@font-face {
  font-family: 'Geomanist Book';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Geomanist-Book.otf');
}

@font-face {
  font-family: 'Geomanist Regular';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Geomanist-Regular.otf');
}
